import { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Plus,
  ChevronDown,
  ChevronUp,
  X,
  Book,
  ExternalLink,
  Edit,
} from "lucide-react";
import {
  collection,
  query,
  onSnapshot,
  addDoc,
  deleteDoc,
  updateDoc,
  doc,
  where,
} from "firebase/firestore";

const ResourcesContent = ({
  db,
  user,
  primaryColor,
  secondaryColor,
  backgroundColor,
  expandedResource,
  setExpandedResource,
}) => {
  const [resources, setResources] = useState([]);
  const [newResource, setNewResource] = useState({
    title: "",
    url: "",
    category: "",
    description: "",
  });
  const [isAddResourceOpen, setIsAddResourceOpen] = useState(false);
  const [expandedMetadata, setExpandedMetadata] = useState(null);
  const expandedViewRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editForm, setEditForm] = useState({
    title: "",
    url: "",
    category: "",
    description: "",
  });
  useEffect(() => {
    if (user) {
      const resourcesCollection = collection(db, "resources");
      const q = query(resourcesCollection, where("userId", "==", user.uid));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const resourcesList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setResources(resourcesList);
      });
      return () => unsubscribe();
    }
  }, [db, user]);

  useEffect(() => {
    if (expandedResource) {
      const resource = resources.find((r) => r.id === expandedResource);
      if (resource && resource.url) {
        fetchMetadata(resource.url);
      }
    }
  }, [expandedResource, resources]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        expandedViewRef.current &&
        !expandedViewRef.current.contains(event.target) &&
        !isEditing
      ) {
        setExpandedResource(null);
        setExpandedMetadata(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setExpandedResource, isEditing]);

  const fetchMetadata = async (url) => {
    try {
      const response = await fetch(
        `https://api.microlink.io?url=${encodeURIComponent(url)}`
      );
      const data = await response.json();
      setExpandedMetadata(data.data);
    } catch (error) {
      console.error("Error fetching metadata:", error);
      setExpandedMetadata(null);
    }
  };

  const handleAddResource = async (e) => {
    e.preventDefault();
    if (newResource.title && newResource.url && user) {
      await addDoc(collection(db, "resources"), {
        ...newResource,
        userId: user.uid,
        createdAt: new Date(),
      });
      setNewResource({ title: "", url: "", category: "", description: "" });
      setIsAddResourceOpen(false);
    }
  };

  const handleDeleteResource = async (id) => {
    await deleteDoc(doc(db, "resources", id));
  };

  const handleEditResource = () => {
    const resource = resources.find((r) => r.id === expandedResource);
    setEditForm({
      title: resource.title,
      url: resource.url,
      category: resource.category,
      description: resource.description,
    });
    setIsEditing(true);
  };

  const handleUpdateResource = async (e) => {
    e.preventDefault();
    if (expandedResource) {
      await updateDoc(doc(db, "resources", expandedResource), editForm);
      setIsEditing(false);
    }
  };

  const categories = [
    "Probability",
    "Statistics",
    "Machine Learning",
    "Deep Learning",
    "Other",
  ];

  const getContrastColor = (bgColor) => {
    const rgb = parseInt(bgColor.slice(1), 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;
    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luma < 128 ? "#ffffff" : "#000000";
  };

  return (
    <div
      className="space-y-6"
      style={{ color: getContrastColor(secondaryColor) }}
    >
      <h2 className="text-2xl font-bold mb-4">Your Learning Resources</h2>

      <div
        className="p-4 rounded shadow"
        style={{ backgroundColor: secondaryColor }}
      >
        <button
          onClick={() => setIsAddResourceOpen(!isAddResourceOpen)}
          className="flex items-center justify-between w-full p-2 rounded transition-colors"
          style={{ backgroundColor: secondaryColor, color: primaryColor }}
        >
          <span>
            <Plus size={18} className="inline-block mr-1" /> Add New Resource
          </span>
          {isAddResourceOpen ? (
            <ChevronUp size={18} />
          ) : (
            <ChevronDown size={18} />
          )}
        </button>

        <AnimatePresence>
          {isAddResourceOpen && (
            <motion.form
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              onSubmit={handleAddResource}
              className="space-y-4 mt-4"
            >
              <input
                type="text"
                value={newResource.title}
                onChange={(e) =>
                  setNewResource({ ...newResource, title: e.target.value })
                }
                placeholder="Resource Title"
                className="w-full p-2 rounded"
                style={{ backgroundColor, color: primaryColor }}
              />
              <input
                type="url"
                value={newResource.url}
                onChange={(e) =>
                  setNewResource({ ...newResource, url: e.target.value })
                }
                placeholder="Resource URL"
                className="w-full p-2 rounded"
                style={{ backgroundColor, color: primaryColor }}
              />
              <select
                value={newResource.category}
                onChange={(e) =>
                  setNewResource({ ...newResource, category: e.target.value })
                }
                className="w-full p-2 rounded"
                style={{ backgroundColor, color: primaryColor }}
              >
                <option value="">Select a category</option>
                {categories.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
              <textarea
                value={newResource.description}
                onChange={(e) =>
                  setNewResource({
                    ...newResource,
                    description: e.target.value,
                  })
                }
                placeholder="Resource Description"
                className="w-full p-2 rounded"
                style={{ backgroundColor, color: primaryColor }}
              />
              <button
                type="submit"
                className="p-2 rounded transition-colors"
                style={{
                  backgroundColor: primaryColor,
                  color: backgroundColor,
                }}
              >
                <Plus size={18} className="inline-block mr-1" /> Add Resource
              </button>
            </motion.form>
          )}
        </AnimatePresence>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {resources.map((resource) => (
          <div
            key={resource.id}
            className="p-4 rounded shadow"
            style={{ backgroundColor: secondaryColor }}
          >
            <div className="flex justify-between items-start">
              <h3
                className="text-xl font-semibold mb-2"
                style={{ color: primaryColor }}
              >
                {resource.title}
              </h3>
              <div>
                <button
                  onClick={() => handleDeleteResource(resource.id)}
                  className="text-red-500 hover:text-red-700"
                >
                  <X size={18} />
                </button>
              </div>
            </div>
            <p className="text-sm mb-2" style={{ color: primaryColor }}>
              {resource.category}
            </p>
            <p className="mb-2" style={{ color: primaryColor }}>
              {resource.description}
            </p>
            <button
              onClick={() => setExpandedResource(resource.id)}
              className="flex items-center hover:underline"
              style={{ color: primaryColor }}
            >
              <Book size={18} className="inline-block mr-1" /> View Resource
            </button>
          </div>
        ))}
      </div>

      {expandedResource && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div
            ref={expandedViewRef}
            className="p-6 rounded-lg max-w-4xl w-full max-h-[90vh] overflow-y-auto"
            style={{
              backgroundColor,
              color: getContrastColor(backgroundColor),
            }}
          >
            {resources.find((r) => r.id === expandedResource) && (
              <>
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-2xl font-semibold">
                    {resources.find((r) => r.id === expandedResource).title}
                  </h3>
                  <div className="flex items-center">
                    <button
                      onClick={handleEditResource}
                      className="text-blue-500 hover:text-blue-700 mr-2"
                    >
                      <Edit size={24} />
                    </button>
                    <button
                      onClick={() => {
                        setExpandedResource(null);
                        setExpandedMetadata(null);
                        setIsEditing(false);
                      }}
                      className="text-gray-500 hover:text-gray-700"
                    >
                      <X size={24} />
                    </button>
                  </div>
                </div>
                {!isEditing ? (
                  <>
                    <p className="text-sm mb-4">
                      {
                        resources.find((r) => r.id === expandedResource)
                          .category
                      }
                    </p>
                    <p className="mb-4">
                      {
                        resources.find((r) => r.id === expandedResource)
                          .description
                      }
                    </p>
                    {expandedMetadata && (
                      <div
                        className="mb-4 p-4 rounded"
                        style={{
                          backgroundColor: secondaryColor,
                          color: getContrastColor(secondaryColor),
                        }}
                      >
                        <h4 className="font-semibold mb-2">Link Preview</h4>
                        {expandedMetadata.image && (
                          <img
                            src={expandedMetadata.image.url}
                            alt={expandedMetadata.title || "Preview image"}
                            className="mb-2 max-w-full h-auto"
                          />
                        )}
                        <h5 className="font-semibold">
                          {expandedMetadata.title}
                        </h5>
                        <p className="text-sm opacity-75">
                          {expandedMetadata.description}
                        </p>
                      </div>
                    )}
                    <a
                      href={
                        resources.find((r) => r.id === expandedResource).url
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="p-2 rounded transition-colors"
                      style={{
                        backgroundColor: primaryColor,
                        color: getContrastColor(primaryColor),
                      }}
                    >
                      <ExternalLink size={18} className="inline-block mr-1" />
                      Open Resource
                    </a>
                  </>
                ) : (
                  <form onSubmit={handleUpdateResource} className="space-y-4">
                    <input
                      type="text"
                      value={editForm.title}
                      onChange={(e) =>
                        setEditForm({ ...editForm, title: e.target.value })
                      }
                      placeholder="Resource Title"
                      className="w-full p-2 rounded"
                      style={{
                        backgroundColor: secondaryColor,
                        color: primaryColor,
                      }}
                    />
                    <input
                      type="url"
                      value={editForm.url}
                      onChange={(e) =>
                        setEditForm({ ...editForm, url: e.target.value })
                      }
                      placeholder="Resource URL"
                      className="w-full p-2 rounded"
                      style={{
                        backgroundColor: secondaryColor,
                        color: primaryColor,
                      }}
                    />
                    <select
                      value={editForm.category}
                      onChange={(e) =>
                        setEditForm({ ...editForm, category: e.target.value })
                      }
                      className="w-full p-2 rounded"
                      style={{
                        backgroundColor: secondaryColor,
                        color: primaryColor,
                      }}
                    >
                      <option value="">Select a category</option>
                      {categories.map((category) => (
                        <option key={category} value={category}>
                          {category}
                        </option>
                      ))}
                    </select>
                    <textarea
                      value={editForm.description}
                      onChange={(e) =>
                        setEditForm({
                          ...editForm,
                          description: e.target.value,
                        })
                      }
                      placeholder="Resource Description"
                      className="w-full p-2 rounded"
                      style={{
                        backgroundColor: secondaryColor,
                        color: primaryColor,
                      }}
                    />
                    <div className="flex justify-end space-x-2">
                      <button
                        type="button"
                        onClick={() => setIsEditing(false)}
                        className="p-2 rounded transition-colors"
                        style={{
                          backgroundColor: secondaryColor,
                          color: primaryColor,
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="p-2 rounded transition-colors"
                        style={{
                          backgroundColor: primaryColor,
                          color: backgroundColor,
                        }}
                      >
                        Update Resource
                      </button>
                    </div>
                  </form>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ResourcesContent;
