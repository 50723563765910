import React, { useCallback, useRef, memo } from "react";
import { motion } from "framer-motion";
import { Plus, X, Download, Paperclip } from "lucide-react";
import "katex/dist/katex.min.css";
import debounce from 'lodash/debounce';
import Masonry from 'react-masonry-css';

const NoteItem = memo(({ note, handleDeleteNote, handleDownloadNote, secondaryColor, Latex }) => {
  return (
    <motion.div
      layout
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.8 }}
      transition={{ duration: 0.3 }}
      className="note-container p-4 rounded relative bg-opacity-50 mb-4"
      style={{
        backgroundColor: secondaryColor,
        fontFamily: "'JetBrains Mono', monospace",
      }}
    >
      <div className="flex-grow">
        {note.type === "link" && note.metadata ? (
          <div>
            <a
              href={note.content}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-400 hover:underline"
            >
              <h3 className="font-bold">{note.metadata.title}</h3>
            </a>
            <p className="text-sm">{note.metadata.description}</p>
            {note.metadata.image && (
              <img
                src={note.metadata.image}
                alt={note.metadata.title}
                className="mt-2 max-w-full h-auto"
              />
            )}
          </div>
        ) : note.fileUrl ? (
          <div>
            {note.fileType && note.fileType.startsWith("image/") ? (
              <img
                src={note.fileUrl}
                alt={note.fileName}
                className="max-w-full h-auto"
              />
            ) : note.fileType === "video/mp4" ? (
              <video controls className="max-w-full h-auto">
                <source src={note.fileUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <a
                href={note.fileUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:underline"
              >
                {note.fileName}
              </a>
            )}
            {note.content && (
              <p className="mt-2">
                <Latex>{note.content}</Latex>
              </p>
            )}
          </div>
        ) : (
          <Latex>{note.content}</Latex>
        )}
      </div>
      <motion.button
        onClick={() => handleDeleteNote(note)}
        className="absolute top-2 right-10 text-red-500 hover:text-red-700"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        <X size={18} />
      </motion.button>
      <motion.button
        onClick={() => handleDownloadNote(note)}
        className="absolute top-2 right-2 text-blue-500 hover:text-blue-700"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        <Download size={18} />
      </motion.button>

      {note.createdAt && (
        <p className="text-xs text-gray-400 mt-2">
          {note.createdAt.toDate().toLocaleString("default", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          })}
        </p>
      )}
    </motion.div>
  );
});

const NotesContent = memo(({
  notes,
  newNote,
  error,
  uploadProgress,
  primaryColor,
  secondaryColor,
  handleNoteChange,
  handleKeyPress,
  handleFileUpload,
  handleAddNote,
  cancelUpload,
  handleDeleteNote,
  handleDownloadNote,
  Latex,
  fileInputRef,
  setNewNote,
  parseMetadata
}) => {
  const debouncedParseMetadata = useRef(
    debounce(async (content) => {
      if (content.startsWith("http://") || content.startsWith("https://")) {
        const metadata = await parseMetadata(content);
        if (metadata) {
          setNewNote((prevNote) => ({
            ...prevNote,
            type: "link",
            metadata,
          }));
        }
      }
    }, 300)
  ).current;

  const handleNoteChangeWrapper = useCallback((e) => {
    const content = e.target.value;
    setNewNote((prevNote) => ({
      ...prevNote,
      type: content.startsWith("http://") || content.startsWith("https://") ? "link" : "text",
      content,
    }));
    debouncedParseMetadata(content);
  }, [setNewNote, debouncedParseMetadata]);

  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 1
  };

  const memoizedHandleDeleteNote = useCallback((note) => {
    handleDeleteNote(note);
  }, [handleDeleteNote]);

  const memoizedHandleDownloadNote = useCallback((note) => {
    handleDownloadNote(note);
  }, [handleDownloadNote]);

  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold mb-4">Your Notes</h2>
      {error ? (
        <div className="text-red-500">{error}</div>
      ) : (
        <>
          <div className="flex space-x-2 mb-4">
            <input
              type="text"
              value={newNote.content}
              onChange={handleNoteChangeWrapper}
              onKeyDown={handleKeyPress}
              placeholder="Add a new note or paste a link"
              className="flex-grow p-2 bg-gray-700 rounded"
            />
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileUpload}
              className="hidden"
            />
            <motion.button
              onClick={handleFileInputClick}
              className="p-2 rounded"
              style={{
                backgroundColor: secondaryColor,
                color: primaryColor,
                fontFamily: "'JetBrains Mono', monospace",
              }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              title="Attach file"
            >
              <Paperclip size={18} />
            </motion.button>
            <motion.button
              onClick={handleAddNote}
              className="p-2 rounded"
              style={{
                backgroundColor: primaryColor,
                fontFamily: "'JetBrains Mono', monospace",
              }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Plus size={18} />
            </motion.button>
          </div>
          {uploadProgress > 0 && (
            <div className="mb-4">
              <p>Upload Progress: {uploadProgress.toFixed(2)}%</p>
              <motion.button onClick={cancelUpload} className="text-red-500">
                Cancel Upload
              </motion.button>
              <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                <div
                  className="bg-blue-600 h-2.5 rounded-full"
                  style={{ width: `${uploadProgress}%` }}
                ></div>
              </div>
            </div>
          )}
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {notes.map((note) => (
              <NoteItem
                key={note.id}
                note={note}
                handleDeleteNote={memoizedHandleDeleteNote}
                handleDownloadNote={memoizedHandleDownloadNote}
                secondaryColor={secondaryColor}
                Latex={Latex}
              />
            ))}
          </Masonry>
        </>
      )}
    </div>
  );
});

export default NotesContent;