import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Save, RefreshCw } from 'lucide-react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { HexColorPicker } from 'react-colorful';

const ColorPickerWithInput = ({ color, onChange, label }) => {
  const [isPickerVisible, setIsPickerVisible] = useState(false);
  const [inputColor, setInputColor] = useState(color);
  const pickerRef = useRef(null);

  useEffect(() => {
    setInputColor(color);
  }, [color]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputColor(value);
    if (/^#[0-9A-Fa-f]{6}$/.test(value)) {
      onChange(value);
    }
  };

  const handleClickOutside = useCallback((event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setIsPickerVisible(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div className="relative">
      <h4 className="text-lg font-semibold mb-2">{label}</h4>
      <div className="flex items-center space-x-2">
        <button
          className="w-10 h-10 rounded cursor-pointer border-2 border-white"
          style={{ backgroundColor: color }}
          onClick={() => setIsPickerVisible(!isPickerVisible)}
        />
        <input
          type="text"
          value={inputColor}
          onChange={handleInputChange}
          className="p-2 w-full bg-gray-700 rounded text-white"
        />
      </div>
      {isPickerVisible && (
        <div className="absolute z-10 mt-2" ref={pickerRef}>
          <HexColorPicker color={color} onChange={onChange} />
        </div>
      )}
    </div>
  );
};

const SettingsContent = ({ db, user, showToast, primaryColor, secondaryColor, backgroundColor, updateColors }) => {
  const [tempColors, setTempColors] = useState({
    primaryColor,
    secondaryColor,
    backgroundColor,
  });
  const [progressGoal, setProgressGoal] = useState(100);
  const [progressBarColor, setProgressBarColor] = useState(primaryColor);
  const [resourcesPerPage, setResourcesPerPage] = useState(10);
  const [notesPerPage, setNotesPerPage] = useState(10);

  const defaultSettings = {
    primaryColor: '#10a7b2',
    secondaryColor: '#004e52',
    backgroundColor: '#1e1e1e',
    progressGoal: 100,
    progressBarColor: '#10a7b2',
    resourcesPerPage: 10,
    notesPerPage: 10,
  };

  useEffect(() => {
    const loadSettings = async () => {
      if (user) {
        const docRef = doc(db, 'userSettings', user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const settings = docSnap.data();
          setTempColors({
            primaryColor: settings.primaryColor || primaryColor,
            secondaryColor: settings.secondaryColor || secondaryColor,
            backgroundColor: settings.backgroundColor || backgroundColor,
          });
          setProgressGoal(settings.progressGoal || 100);
          setProgressBarColor(settings.progressBarColor || primaryColor);
          setResourcesPerPage(settings.resourcesPerPage || 10);
          setNotesPerPage(settings.notesPerPage || 10);
        }
      }
    };
    loadSettings();
  }, [db, user, primaryColor, secondaryColor, backgroundColor]);

  const handleColorChange = useCallback((colorType, color) => {
    setTempColors(prevColors => ({
      ...prevColors,
      [colorType]: color,
    }));
  }, []);

  const handleResetColors = () => {
    setTempColors({
      primaryColor: defaultSettings.primaryColor,
      secondaryColor: defaultSettings.secondaryColor,
      backgroundColor: defaultSettings.backgroundColor,
    });
  };

  const handleResetAllSettings = () => {
    setTempColors({
      primaryColor: defaultSettings.primaryColor,
      secondaryColor: defaultSettings.secondaryColor,
      backgroundColor: defaultSettings.backgroundColor,
    });
    setProgressGoal(defaultSettings.progressGoal);
    setProgressBarColor(defaultSettings.progressBarColor);
    setResourcesPerPage(defaultSettings.resourcesPerPage);
    setNotesPerPage(defaultSettings.notesPerPage);
  };

  const handleSaveSettings = async () => {
    if (user) {
      try {
        const settings = {
          ...tempColors,
          progressGoal,
          progressBarColor,
          resourcesPerPage,
          notesPerPage,
        };
        await setDoc(doc(db, 'userSettings', user.uid), settings);
        updateColors(tempColors);
        showToast('Settings saved successfully');
      } catch (error) {
        console.error('Error saving settings:', error);
        showToast('Failed to save settings');
      }
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 space-y-8">
      <h2 className="text-3xl font-bold mb-6">Settings</h2>
      
      <div className="bg-gray-800 rounded-lg p-6 space-y-6">
        <h3 className="text-2xl font-semibold mb-4">Theme Customization</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <ColorPickerWithInput
            color={tempColors.primaryColor}
            onChange={(color) => handleColorChange('primaryColor', color)}
            label="Primary Color"
          />
          <ColorPickerWithInput
            color={tempColors.secondaryColor}
            onChange={(color) => handleColorChange('secondaryColor', color)}
            label="Secondary Color"
          />
          <ColorPickerWithInput
            color={tempColors.backgroundColor}
            onChange={(color) => handleColorChange('backgroundColor', color)}
            label="Background Color"
          />
        </div>
        <div className="flex justify-end">
          <button
            onClick={handleResetColors}
            className="flex items-center px-4 py-2 rounded mr-4 bg-gray-600 text-white hover:bg-gray-700"
          >
            <RefreshCw size={18} className="mr-2" />
            Reset Colors
          </button>
        </div>
      </div>

      <div className="bg-gray-800 rounded-lg p-6 space-y-6">
        <h3 className="text-2xl font-semibold mb-4">Progress Settings</h3>
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <span className="text-lg">Progress Goal</span>
            <input
              type="number"
              min="0"
              max="100"
              value={progressGoal}
              onChange={(e) => setProgressGoal(parseInt(e.target.value))}
              className="w-20 p-2 rounded bg-gray-700 text-white"
            />
          </div>
          <div className="flex items-center justify-between">
            <span className="text-lg">Progress Bar Color</span>
            <ColorPickerWithInput
              color={progressBarColor}
              onChange={setProgressBarColor}
              label=""
            />
          </div>
        </div>
      </div>

      <div className="bg-gray-800 rounded-lg p-6 space-y-6">
        <h3 className="text-2xl font-semibold mb-4">Resource Settings</h3>
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <span className="text-lg">Resources Per Page</span>
            <input
              type="number"
              min="5"
              max="50"
              value={resourcesPerPage}
              onChange={(e) => setResourcesPerPage(parseInt(e.target.value))}
              className="w-20 p-2 rounded bg-gray-700 text-white"
            />
          </div>
        </div>
      </div>

      <div className="bg-gray-800 rounded-lg p-6 space-y-6">
        <h3 className="text-2xl font-semibold mb-4">Notes Settings</h3>
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <span className="text-lg">Notes Per Page</span>
            <input
              type="number"
              min="5"
              max="50"
              value={notesPerPage}
              onChange={(e) => setNotesPerPage(parseInt(e.target.value))}
              className="w-20 p-2 rounded bg-gray-700 text-white"
            />
          </div>
        </div>
      </div>

      <div className="flex justify-between mt-6">
        <button
          onClick={handleResetAllSettings}
          className="flex items-center px-6 py-3 rounded text-lg bg-red-600 text-white hover:bg-red-700"
        >
          <RefreshCw size={24} className="mr-2" />
          Reset All Settings
        </button>
        <button
          onClick={handleSaveSettings}
          className="flex items-center px-6 py-3 rounded text-lg bg-blue-600 text-white hover:bg-blue-700"
        >
          <Save size={24} className="mr-2" />
          Save All Changes
        </button>
      </div>
    </div>
  );
};

export default SettingsContent;