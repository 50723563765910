import { useState, useEffect, useCallback, useMemo } from "react";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {
  query,
  collection,
  where,
  onSnapshot,
  addDoc,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { Plus, X, Book } from "lucide-react";

const ProgressContent = ({
  db,
  user,
  primaryColor,
  secondaryColor,
  backgroundColor,
  showToast,
  setActiveTab,
  setExpandedResource,
  settings,
}) => {
  const [courses, setCourses] = useState([]);
  const [resources, setResources] = useState([]);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [progressGoal, setProgressGoal] = useState(settings.progressGoal || 100);

  useEffect(() => {
    if (user) {
      const fetchData = async () => {
        const coursesQuery = query(
          collection(db, "courses"),
          where("userId", "==", user.uid)
        );
        const resourcesQuery = query(
          collection(db, "resources"),
          where("userId", "==", user.uid)
        );

        const unsubscribeCourses = onSnapshot(coursesQuery, (snapshot) => {
          const coursesList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setCourses(coursesList);
        });

        const unsubscribeResources = onSnapshot(resourcesQuery, (snapshot) => {
          const resourcesList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setResources(resourcesList);
        });

        return () => {
          unsubscribeCourses();
          unsubscribeResources();
        };
      };

      fetchData();
    }
  }, [db, user]);

  const handleImportResource = useCallback(
    async (resource) => {
      const existingCourse = courses.find(
        (course) => course.resourceId === resource.id
      );
      if (existingCourse) {
        showToast("This resource is already imported");
        return;
      }

      try {
        await addDoc(collection(db, "courses"), {
          name: resource.title,
          progress: 0,
          userId: user.uid,
          resourceId: resource.id,
          url: resource.url,
          category: resource.category,
        });
        showToast("Resource imported successfully");
        setIsImportModalOpen(false);
      } catch (error) {
        console.error("Error importing resource:", error);
        showToast("Failed to import resource");
      }
    },
    [courses, db, showToast, user.uid]
  );

  const handleDeleteCourse = useCallback(
    async (courseId) => {
      try {
        await deleteDoc(doc(db, "courses", courseId));
        showToast("Course removed from progress tracking");
      } catch (error) {
        console.error("Error deleting course:", error);
        showToast("Failed to remove course");
      }
    },
    [db, showToast]
  );

  const handleProgressUpdate = useCallback(
    async (courseId, newProgress) => {
      try {
        await updateDoc(doc(db, "courses", courseId), {
          progress: Math.min(100, Math.max(0, newProgress)),
        });
        showToast("Progress updated successfully");
      } catch (error) {
        console.error("Error updating progress:", error);
        showToast("Failed to update progress");
      }
    },
    [db, showToast]
  );

  const handleGoToResource = useCallback(
    (resourceId) => {
      setActiveTab("resources");
      setExpandedResource(resourceId);
    },
    [setActiveTab, setExpandedResource]
  );

  const getContrastColor = useCallback((bgColor) => {
    const rgb = parseInt(bgColor.slice(1), 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;
    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luma < 128 ? "#ffffff" : "#000000";
  }, []);

  const sortedCourses = useMemo(() => {
    return [...courses].sort((a, b) => b.progress - a.progress);
  }, [courses]);

  const calculateOverallProgress = () => {
    if (courses.length === 0) return 0;
    const totalProgress = courses.reduce((sum, course) => sum + course.progress, 0);
    return Math.round(totalProgress / courses.length);
  };

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold mb-4">Your Learning Progress</h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div
          className="p-6 rounded-lg shadow-md"
          style={{ backgroundColor: secondaryColor }}
        >
          <h3
            className="text-xl font-semibold mb-4"
            style={{ color: getContrastColor(secondaryColor) }}
          >
            Overall Progress
          </h3>
          <div className="w-48 h-48 mx-auto">
            <CircularProgressbar
              value={calculateOverallProgress()}
              text={`${calculateOverallProgress()}%`}
              styles={buildStyles({
                textColor: getContrastColor(secondaryColor),
                pathColor: primaryColor,
                trailColor: backgroundColor,
              })}
            />
          </div>
        </div>

        <div
          className="p-6 rounded-lg shadow-md"
          style={{ backgroundColor: secondaryColor }}
        >
          <h3
            className="text-xl font-semibold mb-4"
            style={{ color: getContrastColor(secondaryColor) }}
          >
            Progress Goal
          </h3>
          <div className="flex items-center space-x-4">
            <input
              type="number"
              min="0"
              max="100"
              value={progressGoal}
              onChange={(e) => setProgressGoal(parseInt(e.target.value))}
              className="w-20 p-2 rounded"
              style={{
                backgroundColor,
                color: getContrastColor(backgroundColor),
              }}
            />
            <span style={{ color: getContrastColor(secondaryColor) }}>%</span>
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center">
        <h3 className="text-xl font-semibold">Tracked Resources</h3>
        <button
          onClick={() => setIsImportModalOpen(true)}
          className="p-2 rounded"
          style={{
            backgroundColor: primaryColor,
            color: getContrastColor(primaryColor),
          }}
        >
          <Plus size={18} className="inline-block mr-1" />
          Import Resource
        </button>
      </div>

      <div className="space-y-4">
        {sortedCourses.map((course) => (
          <div
            key={course.id}
            className="p-4 rounded shadow"
            style={{ backgroundColor: secondaryColor }}
          >
            <div className="flex justify-between items-center mb-2">
              <h3
                className="text-lg font-semibold"
                style={{ color: getContrastColor(secondaryColor) }}
              >
                {course.name}
              </h3>
              <button
                onClick={() => handleDeleteCourse(course.id)}
                className="text-red-500 hover:text-red-700"
              >
                <X size={18} />
              </button>
            </div>
            <p
              className="text-sm mb-2"
              style={{ color: getContrastColor(secondaryColor) }}
            >
              {course.category}
            </p>
            <div className="flex items-center space-x-4">
              <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                <div
                  className="h-2.5 rounded-full"
                  style={{
                    width: `${course.progress}%`,
                    backgroundColor: settings.progressBarColor || primaryColor,
                  }}
                ></div>
              </div>
              <span
                className="text-sm font-medium"
                style={{ color: getContrastColor(secondaryColor) }}
              >
                {course.progress}%
              </span>
              <input
                type="number"
                min="0"
                max="100"
                value={course.progress}
                onChange={(e) =>
                  handleProgressUpdate(course.id, parseInt(e.target.value))
                }
                className="w-16 p-1 rounded"
                style={{
                  backgroundColor,
                  color: getContrastColor(backgroundColor),
                }}
              />
            </div>
            {course.resourceId && (
              <button
                onClick={() => handleGoToResource(course.resourceId)}
                className="text-blue-400 hover:underline mt-2 inline-block"
              >
                <Book size={18} className="inline-block mr-1" />
                Go to Resource
              </button>
            )}
          </div>
        ))}
      </div>

      {isImportModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div
            className="p-6 rounded-lg max-w-2xl w-full max-h-[80vh] overflow-y-auto"
            style={{
              backgroundColor,
              color: getContrastColor(backgroundColor),
            }}
          >
            <div className="flex justify-between items-center mb-4">
              <h3
                className="text-xl font-semibold"
                style={{ color: getContrastColor(backgroundColor) }}
              >
                Import Resource
              </h3>
              <button
                onClick={() => setIsImportModalOpen(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X size={24} />
              </button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {resources.map((resource) => (
                <div
                  key={resource.id}
                  className="p-4 rounded shadow"
                  style={{ backgroundColor: secondaryColor }}
                >
                  <h4
                    className="text-lg font-semibold mb-2"
                    style={{ color: getContrastColor(secondaryColor) }}
                  >
                    {resource.title}
                  </h4>
                  <p
                    className="text-sm mb-2"
                    style={{ color: getContrastColor(secondaryColor) }}
                  >
                    {resource.category}
                  </p>
                  <button
                    onClick={() => handleImportResource(resource)}
                    className="p-2 rounded"
                    style={{
                      backgroundColor: primaryColor,
                      color: getContrastColor(primaryColor),
                    }}
                  >
                    <Plus size={18} className="inline-block mr-1" />
                    Import
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProgressContent;