import HomeContent from "./contents/HomeContent";
import ResourcesContent from "./contents/ResourcesContent";
import ProgressContent from "./contents/ProgressContent";
import NotesContent from "./contents/NotesContent";
import SettingsContent from "./contents/SettingsContent";

import React, { useState, useEffect, useRef } from "react";
import Latex from "react-latex-next";
import {
  Calendar,
  Book,
  Code,
  LogOut,
  Menu,
  Settings2,
  BarChartHorizontalBig,
} from "lucide-react";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  addDoc,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  orderBy,
  serverTimestamp,
  where,
  getDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { motion, AnimatePresence } from "framer-motion";
import katex from "katex";
import "katex/dist/katex.min.css";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

const DEFAULT_PRIMARY_COLOR = "#10a7b2";
const DEFAULT_SECONDARY_COLOR = "#004e52";
const DEFAULT_BACKGROUND_COLOR = "#1e1e1e";

const ToastNotification = ({
  message,
  isVisible,
  primaryColor,
  secondaryColor,
}) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={{ duration: 0.3 }}
          className="fixed bottom-4 right-4 p-3 rounded-lg shadow-lg z-50 max-w-sm"
          style={{
            backgroundColor: secondaryColor,
            color: primaryColor,
            border: `1px solid ${secondaryColor}`,
          }}
        >
          {message}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const AILearningJourneySite = () => {
  const [activeTab, setActiveTab] = useState("home");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [resources, setResources] = useState([]);
  const [notes, setNotes] = useState([]);
  const [expandedResource, setExpandedResource] = useState(null);
  const [newNote, setNewNote] = useState({
    type: "text",
    content: "",
    fileUrl: "",
    fileName: "",
    fileType: "",
  });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const fileInputRef = useRef(null);
  const [uploadTask, setUploadTask] = useState(null);

  const [settings, setSettings] = useState({
    primaryColor: DEFAULT_PRIMARY_COLOR,
    secondaryColor: DEFAULT_SECONDARY_COLOR,
    backgroundColor: DEFAULT_BACKGROUND_COLOR,
    darkMode: false,
    notifications: true,
    fontSize: "medium",
  });

  const [toastMessage, setToastMessage] = useState("");
  const [isToastVisible, setIsToastVisible] = useState(false);

  const showToast = (message) => {
    setToastMessage(message);
    setIsToastVisible(true);
    setTimeout(() => {
      setIsToastVisible(false);
    }, 2000);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsAuthenticated(true);
        setUser(user);

        const docRef = doc(db, "userSettings", user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const userSettings = docSnap.data();
          setSettings((prevSettings) => ({
            ...prevSettings,
            ...userSettings,
          }));
        }
      } else {
        setIsAuthenticated(false);
        setUser(null);
        setSettings({
          primaryColor: DEFAULT_PRIMARY_COLOR,
          secondaryColor: DEFAULT_SECONDARY_COLOR,
          backgroundColor: DEFAULT_BACKGROUND_COLOR,
          darkMode: false,
          notifications: true,
          fontSize: "medium",
        });
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user) {
      const notesCollection = collection(db, "notes");
      const q = query(
        notesCollection,
        where("userId", "==", user.uid),
        orderBy("createdAt", "desc")
      );
      const unsubscribe = onSnapshot(
        q,
        (snapshot) => {
          const notesList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setNotes(notesList);
          setError(null);
        },
        (err) => {
          console.error("Error fetching notes:", err);
          setError(
            "An error occurred while fetching your notes. Please try again later."
          );
        }
      );
      return () => unsubscribe();
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const resourcesCollection = collection(db, "resources");
      const q = query(
        resourcesCollection,
        where("userId", "==", user.uid),
        orderBy("createdAt", "desc")
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const resourcesList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setResources(resourcesList);
      });
      return () => unsubscribe();
    }
  }, [user]);

  const handleLogin = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        setIsAuthenticated(true);
        localStorage.setItem("isAuthenticated", "true");
      })
      .catch((error) => {
        alert("Incorrect email or password, please try again.");
        console.log(error.code, error.message);
      });
  };

  const handleLogout = () => {
    signOut(auth).then(() => {
      setIsAuthenticated(false);
      localStorage.removeItem("isAuthenticated");
    });
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const storageRef = ref(storage, `uploads/${user.uid}/${file.name}`);
    const task = uploadBytesResumable(storageRef, file);

    setUploadTask(task);

    task.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        console.error("Upload failed:", error);
        setUploadProgress(0);
        setUploadTask(null);
      },
      () => {
        getDownloadURL(task.snapshot.ref).then((downloadURL) => {
          setNewNote((prevNote) => ({
            ...prevNote,
            fileUrl: downloadURL,
            fileName: file.name,
            fileType: file.type,
          }));
          setUploadProgress(0);
          setUploadTask(null);
        });
      }
    );
  };

  const cancelUpload = () => {
    if (uploadTask) {
      try {
        uploadTask.cancel();
      } catch (error) {
        if (error.code === "storage/canceled") {
          console.log("Upload canceled by the user.");
        } else {
          console.error("Upload failed:", error);
        }
      }
      setUploadProgress(0);
      setUploadTask(null);
    }
  };

  const handleAddNote = async () => {
    if ((newNote.content.trim() || newNote.fileUrl) && user) {
      await addDoc(collection(db, "notes"), {
        ...newNote,
        userId: user.uid,
        createdAt: serverTimestamp(),
      });
      setNewNote({
        type: "text",
        content: "",
        fileUrl: "",
        fileName: "",
        fileType: "",
      });
      fileInputRef.current.value = null;
    }
  };

  const handleDeleteFile = async (fileUrl) => {
    const storageRef = ref(storage, fileUrl);
    try {
      await deleteObject(storageRef);
      console.log("File deleted successfully");
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  const handleDeleteNote = async (note) => {
    if (note.fileUrl) {
      await handleDeleteFile(note.fileUrl);
    }
    await deleteDoc(doc(db, "notes", note.id));
  };

  const handleDownloadNote = (note) => {
    let content = "";

    if (note.type === "link" && note.metadata) {
      content = `# [${note.metadata.title}](${note.content})\n\n${note.metadata.description}\n\n`;
      if (note.metadata.image) {
        content += `![${note.metadata.title}](${note.metadata.image})\n\n`;
      }
    } else if (note.fileUrl) {
      if (note.fileType && note.fileType.startsWith("image/")) {
        content = `![${note.fileName}](${note.fileUrl})\n\n`;
      } else if (note.fileType === "video/mp4") {
        content = `[${note.fileName}](${note.fileUrl})\n\n`;
      } else {
        content = `[${note.fileName}](${note.fileUrl})\n\n`;
      }
      if (note.content) {
        content += `${note.content}\n\n`;
      }
    } else {
      content = note.content + "\n\n";
    }

    content = content.replace(/\$(.*?)\$/g, (match, latex) => {
      try {
        return katex.renderToString(latex, { throwOnError: false });
      } catch (error) {
        console.error("Error rendering LaTeX:", error);
        return match;
      }
    });

    const cleanContent = content
      .replace(/!\[.*?\]\(.*?\)|\[.*?\]\(.*?\)/g, "")
      .trim();
    const firstWordMatch = cleanContent.match(/\b\w+/);
    const firstWord = firstWordMatch ? firstWordMatch[0] : "note";

    const formatDate = (date) => {
      let d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear(),
        hour = "" + d.getHours(),
        minute = "" + d.getMinutes();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      if (hour.length < 2) hour = "0" + hour;
      if (minute.length < 2) minute = "0" + minute;

      return [year, month, day].join("") + "_" + [hour, minute].join("");
    };

    const timestamp = note.createdAt
      ? formatDate(note.createdAt.toDate())
      : formatDate(new Date());
    const filename = `${firstWord}_${timestamp}.md`;

    const blob = new Blob([content], { type: "text/markdown;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    URL.revokeObjectURL(link.href);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleAddNote();
    }
  };

  const parseMetadata = (url) => {
    return new Promise((resolve) => {
      if (
        url &&
        url.includes &&
        (url.includes("x.com") || url.includes("twitter.com "))
      ) {
        const tweetIdMatch = url.match(/\/status\/(\d+)/);
        if (tweetIdMatch && tweetIdMatch[1]) {
          const tweetId = tweetIdMatch[1];

          fetch(`https://api.vxtwitter.com/Twitter/status/${tweetId}`)
            .then((response) => response.json())
            .then((data) => {
              const metadata = {
                url: data.tweetURL,
                title: `Tweet by ${data.user_name}`,
                description: data.text,
                image: data.mediaURLs[0] || "",
              };
              resolve(metadata);
            })
            .catch(() => {
              resolve({ url, title: url, description: "", image: "" });
            });
        } else {
          resolve({ url, title: url, description: "", image: "" });
        }
      } else {
        const proxyUrl = "https://corsproxy.io/?";
        const targetUrl = proxyUrl + encodeURIComponent(url);

        const parser = new DOMParser();
        fetch(targetUrl)
          .then((response) => response.text())
          .then((html) => {
            const doc = parser.parseFromString(html, "text/html");
            const metadata = {
              url,
              title: doc.querySelector("title")?.textContent || "",
              description:
                doc
                  .querySelector('meta[name="description"]')
                  ?.getAttribute("content") || "",
              image:
                doc
                  .querySelector('meta[property="og:image"]')
                  ?.getAttribute("content") || "",
            };
            resolve(metadata);
          })
          .catch(() => {
            resolve({ url, title: url, description: "", image: "" });
          });
      }
    });
  };

  const handleNoteChange = async (e) => {
    const content = e.target.value;
    setNewNote((prevNote) => ({
      ...prevNote,
      content,
    }));

    if (content.startsWith("http://") || content.startsWith("https://")) {
      const metadata = await parseMetadata(content);
      if (metadata) {
        setNewNote((prevNote) => ({
          ...prevNote,
          type: "link",
          content,
          metadata,
        }));
      }
    } else {
      setNewNote((prevNote) => ({
        ...prevNote,
        type: "text",
        content,
      }));
    }
  };

  const tabs = [
    { id: "home", label: "Home", icon: Calendar },
    { id: "progress", label: "Progress", icon: BarChartHorizontalBig },
    { id: "resources", label: "Resources", icon: Code },
    { id: "notes", label: "Notes", icon: Book },
    { id: "settings", label: "Settings", icon: Settings2 },
  ];

  const updateColors = (newColors) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      ...newColors,
    }));
  };

  const content = {
    home: (
      <HomeContent
  user={user}
  db={db}
  resources={resources}
  notes={notes}
  primaryColor={settings.primaryColor}
  secondaryColor={settings.secondaryColor}
  backgroundColor={settings.backgroundColor}
  setActiveTab={setActiveTab}
  setExpandedResource={setExpandedResource}
/>
    ),
    progress: (
      <ProgressContent
        db={db}
        user={user}
        primaryColor={settings.primaryColor}
        secondaryColor={settings.secondaryColor}
        backgroundColor={settings.backgroundColor}
        showToast={showToast}
        setActiveTab={setActiveTab}
        setExpandedResource={setExpandedResource}
        settings={settings}
      />
    ),
    resources: (
      <ResourcesContent
        db={db}
        user={user}
        primaryColor={settings.primaryColor}
        secondaryColor={settings.secondaryColor}
        backgroundColor={settings.backgroundColor}
        expandedResource={expandedResource}
        setExpandedResource={setExpandedResource}
        resourcesPerPage={settings.resourcesPerPage || 10}
      />
    ),
    notes: (
      <NotesContent
        notes={notes}
        newNote={newNote}
        error={error}
        uploadProgress={uploadProgress}
        primaryColor={settings.primaryColor}
        secondaryColor={settings.secondaryColor}
        handleNoteChange={handleNoteChange}
        handleKeyPress={handleKeyPress}
        handleFileUpload={handleFileUpload}
        handleAddNote={handleAddNote}
        cancelUpload={cancelUpload}
        handleDeleteNote={handleDeleteNote}
        handleDownloadNote={handleDownloadNote}
        Latex={Latex}
        fileInputRef={fileInputRef}
        setNewNote={setNewNote}
        parseMetadata={parseMetadata}
        notesPerPage={settings.notesPerPage || 10}
      />
    ),
    settings: (
      <SettingsContent
        db={db}
        user={user}
        showToast={showToast}
        primaryColor={settings.primaryColor}
        secondaryColor={settings.secondaryColor}
        backgroundColor={settings.backgroundColor}
        updateColors={updateColors}
      />
    ),
  };

  if (!isAuthenticated) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className="min-h-screen flex items-center justify-center"
        style={{
          backgroundColor: settings.backgroundColor,
          fontFamily: "'JetBrains Mono', monospace",
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleLogin();
          }}
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.3 }}
            className="bg-white p-8 rounded shadow-md w-full max-w-md"
          >
            <h2 className="text-2xl font-bold mb-4">Login</h2>
            <input
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-2 mb-4 border rounded"
            />
            <input
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-2 mb-4 border rounded"
            />
            <motion.button
              type="submit"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="w-full p-2 rounded text-white"
              style={{ backgroundColor: settings.primaryColor }}
            >
              Log In
            </motion.button>
          </motion.div>
        </form>
      </motion.div>
    );
  }

  return (
    <div
      className="min-h-screen flex flex-col"
      style={{
        backgroundColor: settings.backgroundColor,
        fontSize:
          settings.fontSize === "small"
            ? "14px"
            : settings.fontSize === "large"
            ? "18px"
            : "16px",
      }}
    >
      <header
        className="p-4 flex justify-between items-center"
        style={{ backgroundColor: settings.secondaryColor }}
      >
        <h1
          className="text-2xl font-bold"
          style={{
            color: settings.primaryColor,
            fontFamily: "'JetBrains Mono', monospace",
          }}
        >
          Kesku's Notes
        </h1>
        <nav className="hidden md:flex items-center">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`mx-2 p-2 rounded transition-colors duration-200 ${
                activeTab === tab.id
                  ? "bg-opacity-20 bg-white"
                  : "hover:bg-opacity-10 hover:bg-white"
              }`}
              style={{
                color: settings.primaryColor,
                fontFamily: "'JetBrains Mono', monospace",
              }}
            >
              <tab.icon className="inline-block mr-1" size={18} />
              {tab.label}
            </button>
          ))}
          <button
            onClick={handleLogout}
            className="ml-4 p-2 rounded transition-colors duration-200 hover:bg-opacity-90"
            style={{ backgroundColor: settings.primaryColor, color: "white" }}
          >
            <LogOut size={18} />
          </button>
        </nav>
        <button
          className="md:hidden"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          style={{ color: settings.primaryColor }}
        >
          <Menu size={24} />
        </button>
      </header>
      {isMenuOpen && (
        <div
          className="md:hidden"
          style={{
            backgroundColor: settings.secondaryColor,
            fontFamily: "'JetBrains Mono', monospace",
          }}
        >
          {tabs.map((tab) => (
            <button
              key={tab.id}
              onClick={() => {
                setActiveTab(tab.id);
                setIsMenuOpen(false);
              }}
              className={`block w-full text-left p-2 transition-colors duration-200 ${
                activeTab === tab.id
                  ? "bg-opacity-20 bg-white"
                  : "hover:bg-opacity-10 hover:bg-white"
              }`}
              style={{
                color: settings.primaryColor,
                fontFamily: "'JetBrains Mono', monospace",
              }}
            >
              <tab.icon className="inline-block mr-2" size={18} />
              {tab.label}
            </button>
          ))}
          <button
            onClick={handleLogout}
            className="block w-full text-left p-2 transition-colors duration-200 hover:bg-opacity-10 hover:bg-white"
            style={{
              color: settings.primaryColor,
              fontFamily: "'JetBrains Mono', monospace",
            }}
          >
            <LogOut className="inline-block mr-2" size={18} />
            Logout
          </button>
        </div>
      )}
      <main className="flex-grow p-4 md:p-8 text-white">
        {content[activeTab]}
      </main>
      <ToastNotification
        message={toastMessage}
        isVisible={isToastVisible}
        primaryColor={settings.primaryColor}
        secondaryColor={settings.secondaryColor}
      />
    </div>
  );
};

export default AILearningJourneySite;
