import { useEffect, useState } from "react";
import { Code, Book, ArrowRight, FileText, BarChart2 } from "lucide-react";
import quotesy from "quotesy";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { collection, query, where, onSnapshot } from "firebase/firestore";

const HomeContent = ({
  user,
  db,
  resources,
  notes,
  primaryColor,
  secondaryColor,
  backgroundColor,
  setActiveTab,
  setExpandedResource,
}) => {
  const [currentQuote, setCurrentQuote] = useState(null);
  const [recentResources, setRecentResources] = useState([]);
  const [recentNotes, setRecentNotes] = useState([]);
  const [isQuoteLoading, setIsQuoteLoading] = useState(true);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const quote = quotesy.random();
    setCurrentQuote(quote);
    setIsQuoteLoading(false);
  }, []);

  useEffect(() => {
    const sortedResources = [...resources].sort((a, b) => b.createdAt - a.createdAt);
    setRecentResources(sortedResources.slice(0, 3));

    const sortedNotes = [...notes].sort((a, b) => b.createdAt - a.createdAt);
    setRecentNotes(sortedNotes.slice(0, 3));
  }, [resources, notes]);

  useEffect(() => {
    if (user && db) {
      const coursesQuery = query(
        collection(db, "courses"),
        where("userId", "==", user.uid)
      );

      const unsubscribe = onSnapshot(coursesQuery, (snapshot) => {
        const coursesList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCourses(coursesList);
      });

      return () => unsubscribe();
    }
  }, [user, db]);

  const getContrastColor = (bgColor) => {
    const rgb = parseInt(bgColor.slice(1), 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;
    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luma < 128 ? "#ffffff" : "#000000";
  };

  const handleGoToResource = (resourceId) => {
    setActiveTab("resources");
    setExpandedResource(resourceId);
  };

  const calculateOverallProgress = () => {
    if (courses.length === 0) return 0;
    const totalProgress = courses.reduce((sum, course) => sum + course.progress, 0);
    return Math.round(totalProgress / courses.length);
  };

  const getNextMilestone = (progress) => {
    const milestones = [25, 50, 75, 100];
    return milestones.find(milestone => milestone > progress) || 100;
  };

  return (
    <div className="space-y-6">
      <h2
        className="text-2xl font-bold mb-4"
        style={{ color: getContrastColor(backgroundColor) }}
      >
        Welcome back, {user.displayName || user.email}!
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div
          className="p-6 rounded-lg shadow-md"
          style={{
            backgroundColor: secondaryColor,
            color: getContrastColor(secondaryColor),
          }}
        >
          <h3 className="text-xl font-semibold mb-4">Quick Stats</h3>
          <div className="space-y-2">
            <p><Book className="inline-block mr-2" size={18} /> Total Resources: {resources.length}</p>
            <p><FileText className="inline-block mr-2" size={18} /> Total Notes: {notes.length}</p>
            <p><Code className="inline-block mr-2" size={18} /> Last Resource Added: {recentResources[0]?.title || "None"}</p>
          </div>
        </div>

        <div
          className="p-6 rounded-lg shadow-md"
          style={{
            backgroundColor: secondaryColor,
            color: getContrastColor(secondaryColor),
          }}
        >
          <h3 className="text-xl font-semibold mb-4">Overall Progress</h3>
          <div className="flex items-center">
            <div className="w-24 h-24 mr-4">
              <CircularProgressbar
                value={calculateOverallProgress()}
                text={`${calculateOverallProgress()}%`}
                styles={buildStyles({
                  textColor: getContrastColor(secondaryColor),
                  pathColor: primaryColor,
                  trailColor: backgroundColor,
                })}
              />
            </div>
            <div>
              <p className="mb-2">Tracked Resources: {courses.length}</p>
              <p className="mb-2">Next milestone: {getNextMilestone(calculateOverallProgress())}%</p>
              <button
                onClick={() => setActiveTab("progress")}
                className="text-sm hover:underline flex items-center"
                style={{ color: primaryColor }}
              >
                <BarChart2 size={18} className="mr-1" />
                View Detailed Progress
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="p-6 rounded-lg shadow-md"
        style={{
          backgroundColor: secondaryColor,
          color: getContrastColor(secondaryColor),
        }}
      >
        <h3 className="text-xl font-semibold mb-4">Recently Added Resources</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {recentResources.map((resource) => (
            <div
              key={resource.id}
              className="p-4 rounded-lg shadow-md"
              style={{
                backgroundColor: backgroundColor,
                color: getContrastColor(backgroundColor),
              }}
            >
              <h4 className="font-semibold mb-2">{resource.title}</h4>
              <p className="text-sm mb-2">{resource.category}</p>
              <button
                onClick={() => handleGoToResource(resource.id)}
                className="text-sm hover:underline flex items-center"
                style={{ color: primaryColor }}
              >
                <Book size={18} className="mr-1" />
                Go to Resource
              </button>
            </div>
          ))}
        </div>
        <button
          onClick={() => setActiveTab("resources")}
          className="mt-4 px-4 py-2 rounded transition-colors flex items-center"
          style={{
            backgroundColor: primaryColor,
            color: getContrastColor(primaryColor),
          }}
        >
          View All Resources <ArrowRight size={18} className="ml-2" />
        </button>
      </div>

      <div
        className="p-6 rounded-lg shadow-md"
        style={{
          backgroundColor: secondaryColor,
          color: getContrastColor(secondaryColor),
        }}
      >
        <h3 className="text-xl font-semibold mb-4">Recent Notes</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {recentNotes.map((note) => (
            <div
              key={note.id}
              className="p-4 rounded-lg shadow-md"
              style={{
                backgroundColor: backgroundColor,
                color: getContrastColor(backgroundColor),
              }}
            >
              <h4 className="font-semibold mb-2">{note.title || "Untitled Note"}</h4>
              <p className="text-sm mb-2">{note.content.substring(0, 50)}...</p>
              <p className="text-xs text-gray-500">
                {note.createdAt.toDate().toLocaleDateString()}
              </p>
            </div>
          ))}
        </div>
        <button
          onClick={() => setActiveTab("notes")}
          className="mt-4 px-4 py-2 rounded transition-colors flex items-center"
          style={{
            backgroundColor: primaryColor,
            color: getContrastColor(primaryColor),
          }}
        >
          View All Notes <ArrowRight size={18} className="ml-2" />
        </button>
      </div>

      <div
        className="p-6 rounded-lg shadow-md"
        style={{
          backgroundColor: secondaryColor,
          color: getContrastColor(secondaryColor),
        }}
      >
        {isQuoteLoading ? (
          <p>Loading quote...</p>
        ) : currentQuote ? (
          <blockquote className="text-lg italic">
            "{currentQuote.text}" - {currentQuote.author || "Unknown"}
          </blockquote>
        ) : (
          <p>No quote available</p>
        )}
        <button
          onClick={() => {
            setIsQuoteLoading(true);
            setCurrentQuote(quotesy.random());
            setIsQuoteLoading(false);
          }}
          className="mt-4 px-4 py-2 rounded transition-colors flex items-center"
          style={{
            backgroundColor: primaryColor,
            color: getContrastColor(primaryColor),
          }}
        >
          Get New Quote <ArrowRight size={18} className="ml-2" />
        </button>
      </div>
    </div>
  );
};

export default HomeContent;